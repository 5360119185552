import React from "react";

export const SubscriptionPage = () => {
  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">Subscription</h4>
          </div>
        </div>
      </div>
      <div className="mt-5 container">
        <div className="card-group">
          <div className="card rounded-0">
            <div class="card-header bg-primary-2 text-white">
              <h4 className="mb-0 text-center">Launch special</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">$0</h1>
              <h4 className="text-center">monthly</h4>
              <h4 className="text-center">
                <span className="badge bg-info-2">Limited time!</span>
              </h4>
              <div className="d-flex justify-content-center">
                <ul className="mt-4">
                  <li>
                    <span className="fw-bold">25</span> products sourced from
                    South Korea
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card rounded-0">
            <div class="card-header bg-light">
              <h4 className="mb-0 text-center">BASIC</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">
                $19
                <span
                  style={{
                    fontSize: "18px",
                    position: "relative",
                    top: "-15px",
                  }}
                >
                  .95
                </span>
              </h1>
              <h4 className="text-center">monthly</h4>
              <h4 className="text-center">
                <span className="badge bg-secondary">Coming soon</span>
              </h4>
              <div className="d-flex justify-content-center">
                <ul className="mt-4">
                  <li>
                    <span className="fw-bold">25</span> products sourced from
                    South Korea
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card rounded-0">
            <div class="card-header bg-light">
              <h4 className="mb-0 text-center">PRO</h4>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">
                $39
                <span
                  style={{
                    fontSize: "18px",
                    position: "relative",
                    top: "-15px",
                  }}
                >
                  .95
                </span>
              </h1>
              <h4 className="text-center">monthly</h4>
              <h4 className="text-center">
                <span className="badge bg-secondary">Coming soon</span>
              </h4>
              <div className="d-flex justify-content-center">
                <ul className="mt-4">
                  <li>
                    <span className="fw-bold">100</span> products sourced from
                    South Korea
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
