import SideMenu from "../../components/side-menu/side-menu.component";
import { useState, useEffect } from "react";

//Dependencies
import { Switch, Route } from "react-router-dom";

//Pages
import { HomePage } from "../home/home.component";
import { SearchPage } from "../search/search.component";
import { Imports } from "../imports/imports.component";
import { MyProductsPage } from "../my-products/my-products.component";
import { Orders } from "../orders/orders.component";
import { NotificationsPage } from "../notifications/notifications.component";
import { HelpCentrePage } from "../help-centre/help-centre.component";
import { SettingsPage } from "../settings/settings.component";
import { PaymentCancelledPage } from "../orders/payment-cancelled.component";
import { PaymentSuccessPage } from "../orders/payment-success.component";
import { SubscriptionPage } from "../subscription/subscription.component";

const Dashboard = () => {
  // return (
  //   <div className="dashboard">
  //     <div
  //       className="row"
  //       style={{ height: "100vh", marginLeft: "0px", marginRight: "0px" }}
  //     >
  //       <div
  //         className="col-md-2 shadow"
  //         style={{
  //           position: "fixed",
  //           top: 0,
  //           left: 0,
  //           padding: "0px",
  //           height: "100vh",
  //           //boxShadow: "0 .5rem 1rem -2px rgba(0,0,0,.15)",
  //         }}
  //       >
  //         <SideMenu selectedPage="Notifications" />
  //       </div>
  //       <div
  //         className="col-md-10 offset-md-2"
  //         style={{
  //           backgroundColor: "#f3f3f3",
  //           padding: "0px",
  //           //borderLeft: "1px solid gray",
  //         }}
  //       >
  //         <Switch>
  //           <Route exact path="/" component={HomePage} />
  //           <Route exact path="/search-products" component={SearchPage} />
  //           <Route exact path="/my-products" component={MyProductsPage} />
  //           <Route exact path="/imports" component={Imports} />
  //           <Route exact path="/orders" component={Orders} />
  //           <Route exact path="/notifications" component={NotificationsPage} />
  //           <Route exact path="/help-centre" component={HelpCentrePage} />
  //           <Route exact path="/settings" component={SettingsPage} />
  //         </Switch>
  //       </div>
  //     </div>
  //   </div>
  // );

  //   const [isMobile, setIsMobile] = useState(false);

  //   useEffect(() => {
  //     setIsMobile(useCheckMobileScreen());
  // }, []);

  const [width, setWidth] = useState(window.innerWidth);

  // const useCheckMobileScreen = () => {
  //   const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // useEffect(() => {
  //   console.log(window.innerWidth);
  // }, []);

  return (
    <div
    //className="d-flex justify-content-start"
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          padding: "0px",
          height: "100vh",
        }}
      >
        <SideMenu selectedPage="Notifications" iconsOnly={width <= 768} />
      </div>
      <div
        //className="flex-grow-1"
        style={{
          backgroundColor: "#f3f3f3",
          marginLeft: width <= 768 ? "75px" : "285px",
          minHeight: "100vh",
        }}
      >
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/search-products" component={SearchPage} />
          <Route exact path="/my-products" component={MyProductsPage} />
          <Route exact path="/imports" component={Imports} />
          <Route exact path="/orders" component={Orders} />
          <Route exact path="/notifications" component={NotificationsPage} />
          <Route exact path="/help-centre" component={HelpCentrePage} />
          <Route exact path="/settings" component={SettingsPage} />
          <Route exact path="/subscription" component={SubscriptionPage} />
          <Route
            path="/orders/payment/success/:payment_ref_number"
            component={PaymentSuccessPage}
          />
          <Route
            path="/orders/payment/cancel/:shopify_order_number"
            component={PaymentCancelledPage}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Dashboard;
